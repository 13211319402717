ul.lst-kix_list_1-0 {
  list-style-type: none;
}

.lst-kix_list_3-0>li:before {
  content: "\0025cf  ";
}

ul.lst-kix_list_5-7 {
  list-style-type: none;
}

ul.lst-kix_list_5-8 {
  list-style-type: none;
}

.lst-kix_list_3-1>li:before {
  content: "o  ";
}

.lst-kix_list_3-2>li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_5-5 {
  list-style-type: none;
}

ul.lst-kix_list_5-6 {
  list-style-type: none;
}

ul.lst-kix_list_1-3 {
  list-style-type: none;
}

.lst-kix_list_3-5>li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_5-0 {
  list-style-type: none;
}

ul.lst-kix_list_1-4 {
  list-style-type: none;
}

ul.lst-kix_list_1-1 {
  list-style-type: none;
}

.lst-kix_list_3-4>li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_1-2 {
  list-style-type: none;
}

ul.lst-kix_list_5-3 {
  list-style-type: none;
}

ul.lst-kix_list_1-7 {
  list-style-type: none;
}

.lst-kix_list_3-3>li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_5-4 {
  list-style-type: none;
}

ul.lst-kix_list_1-8 {
  list-style-type: none;
}

ul.lst-kix_list_5-1 {
  list-style-type: none;
}

ul.lst-kix_list_1-5 {
  list-style-type: none;
}

ul.lst-kix_list_5-2 {
  list-style-type: none;
}

ul.lst-kix_list_1-6 {
  list-style-type: none;
}

.lst-kix_list_3-8>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_3-6>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_3-7>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_5-0>li:before {
  content: "\0025cf  ";
}

.lst-kix_list_4-8>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_5-3>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_4-7>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_5-2>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_5-1>li:before {
  content: "o  ";
}

ul.lst-kix_list_4-8 {
  list-style-type: none;
}

.lst-kix_list_5-7>li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_4-6 {
  list-style-type: none;
}

.lst-kix_list_5-6>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_5-8>li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_4-7 {
  list-style-type: none;
}

ul.lst-kix_list_4-0 {
  list-style-type: none;
}

ul.lst-kix_list_4-1 {
  list-style-type: none;
}

.lst-kix_list_5-4>li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_4-4 {
  list-style-type: none;
}

.lst-kix_list_5-5>li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_4-5 {
  list-style-type: none;
}

ul.lst-kix_list_4-2 {
  list-style-type: none;
}

ul.lst-kix_list_4-3 {
  list-style-type: none;
}

.lst-kix_list_6-1>li:before {
  content: "o  ";
}

.lst-kix_list_6-3>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_6-0>li:before {
  content: "\0025cf  ";
}

.lst-kix_list_6-4>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_6-2>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_6-8>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_6-5>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_6-7>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_7-0>li:before {
  content: "\0025cf  ";
}

.lst-kix_list_6-6>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_2-6>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_2-7>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_7-4>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_7-6>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_2-4>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_2-5>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_2-8>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_7-1>li:before {
  content: "o  ";
}

.lst-kix_list_7-5>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_7-2>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_7-3>li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_7-5 {
  list-style-type: none;
}

ul.lst-kix_list_7-6 {
  list-style-type: none;
}

ul.lst-kix_list_7-3 {
  list-style-type: none;
}

ul.lst-kix_list_3-7 {
  list-style-type: none;
}

ul.lst-kix_list_7-4 {
  list-style-type: none;
}

ul.lst-kix_list_3-8 {
  list-style-type: none;
}

ul.lst-kix_list_7-7 {
  list-style-type: none;
}

ul.lst-kix_list_7-8 {
  list-style-type: none;
}

ul.lst-kix_list_3-1 {
  list-style-type: none;
}

ul.lst-kix_list_3-2 {
  list-style-type: none;
}

.lst-kix_list_7-8>li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_3-0 {
  list-style-type: none;
}

ul.lst-kix_list_7-1 {
  list-style-type: none;
}

ul.lst-kix_list_3-5 {
  list-style-type: none;
}

ul.lst-kix_list_7-2 {
  list-style-type: none;
}

ul.lst-kix_list_3-6 {
  list-style-type: none;
}

ul.lst-kix_list_3-3 {
  list-style-type: none;
}

ul.lst-kix_list_7-0 {
  list-style-type: none;
}

.lst-kix_list_7-7>li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_3-4 {
  list-style-type: none;
}

.lst-kix_list_4-0>li:before {
  content: "\0025cf  ";
}

.lst-kix_list_4-1>li:before {
  content: "o  ";
}

.lst-kix_list_4-4>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_4-3>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_4-5>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_4-2>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_4-6>li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_6-6 {
  list-style-type: none;
}

ul.lst-kix_list_6-7 {
  list-style-type: none;
}

ul.lst-kix_list_6-4 {
  list-style-type: none;
}

ul.lst-kix_list_2-8 {
  list-style-type: none;
}

ul.lst-kix_list_6-5 {
  list-style-type: none;
}

ul.lst-kix_list_6-8 {
  list-style-type: none;
}

ul.lst-kix_list_2-2 {
  list-style-type: none;
}

.lst-kix_list_1-0>li:before {
  content: "\0025cf  ";
}

ul.lst-kix_list_2-3 {
  list-style-type: none;
}

ul.lst-kix_list_2-0 {
  list-style-type: none;
}

ul.lst-kix_list_2-1 {
  list-style-type: none;
}

ul.lst-kix_list_6-2 {
  list-style-type: none;
}

ul.lst-kix_list_2-6 {
  list-style-type: none;
}

ul.lst-kix_list_6-3 {
  list-style-type: none;
}

.lst-kix_list_1-1>li:before {
  content: "o  ";
}

.lst-kix_list_1-2>li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_2-7 {
  list-style-type: none;
}

ul.lst-kix_list_6-0 {
  list-style-type: none;
}

ul.lst-kix_list_2-4 {
  list-style-type: none;
}

ul.lst-kix_list_6-1 {
  list-style-type: none;
}

ul.lst-kix_list_2-5 {
  list-style-type: none;
}

.lst-kix_list_1-3>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_1-4>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_1-7>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_1-5>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_1-6>li:before {
  content: "\0025aa  ";
}

li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}

.lst-kix_list_2-0>li:before {
  content: "\0025cf  ";
}

.lst-kix_list_2-1>li:before {
  content: "o  ";
}

.lst-kix_list_1-8>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_2-2>li:before {
  content: "\0025aa  ";
}

.lst-kix_list_2-3>li:before {
  content: "\0025aa  ";
}

ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.c20 {
  background-color: #ffffff;
  margin-left: 18pt;
  padding-top: 0pt;
  text-indent: 36pt;
  padding-bottom: 8pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c15 {
  background-color: #ffffff;
  margin-left: 0pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 14pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c18 {
  background-color: #ffffff;
  margin-left: 0pt;
  padding-top: 14pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c4 {
  background-color: #ffffff;
  /* margin-left: 24pt; */
  padding-top: 0pt;
  /* text-indent: -18pt; */
  padding-bottom: 8pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c11 {
  background-color: #ffffff;
  margin-left: 0pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c13 {
  background-color: #ffffff;
  /* margin-left: 36pt; */
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c14 {
  background-color: #ffffff;
  margin-left: 22pt;
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c16 {
  background-color: #ffffff;
  /* margin-left: 54pt; */
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c31 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c26 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c0 {
  color: #333333;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-style: normal;
}

.c35 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c10 {
  background-color: #ffffff;
  padding-top: 12pt;
  /* padding-bottom: 12pt; */
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c39 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c7 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-style: normal;
}

.c40 {
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}

.c23 {
  -webkit-text-decoration-skip: none;
  color: #954f72;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}

.c1 {
  font-size: 12pt;
  color: #23232d;
  font-weight: 700;
}

.c5 {
  color: #23232d;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}

.c6 {
  font-size: 12pt;
  color: #333333;
  font-weight: 700;
}

.c9 {
  font-size: 22px;
  font-weight: bold;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}

.c3 {
  font-size: 12pt;
  font-weight: 400;
}

.c44 {
  font-weight: 400;
  font-size: 10.5pt;
}

.c17 {
  font-size: 7pt;
  font-weight: 400;
}

.c25 {
  font-size: 12pt;
  font-weight: 700;
}

.c12 {
  font-weight: 700;
  font-size: 14pt;
}

.c29 {
  background-color: #ffffff;
  /* max-width: 468pt; */
  padding: 22pt 72pt 72pt 72pt;
}

.s-new {
  padding: 80px;
  padding-top: 50px;
}

@media only screen and (max-width: 600px) {
  .s-new {
    padding: 45px;
    padding-top: 50px;
  }

}

.c28 {
  font-size: 20pt;
  font-weight: 400;
}

.c33 {
  font-size: 12pt;
  font-weight: 400;
}

.c2 {
  color: inherit;
  text-decoration: inherit;
}

.c24 {
  padding: 0;
  margin: 0;
}

.c47 {
  font-weight: 400;
}

.c41 {
  margin-left: 20pt;
}

.c27 {
  color: #23232d;
}

.c45 {
  margin-left: 18pt;
}

.c42 {
  color: #954f72;
}

.c22 {
  font-style: italic;
}

.c46 {
  color: #0000ff;
}

.c38 {
  font-size: 24pt;
}

.c8 {
  color: #333333;
}

.c34 {
  text-indent: 18pt;
}

.c36 {
  height: 11pt;
}

.c21 {
  /* margin-left: 36pt; */
}

.c37 {
  color: #1d1d1d;
}

.c30 {
  background-color: #fafafa;
}

.c19 {
  text-indent: -18pt;
}

.c43 {
  color: #2d2d2d;
}

.c32 {
  color: #000000;
}

.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  line-height: 1.0791666666666666;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}