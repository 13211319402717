.red-btn {
  background-color: #ec2828;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 15px;
  color: #fff;
  cursor: default !important;
}

.redd_btn {
  background-color: #ec2828;
  border: none;
  border-radius: 30px;
  width: 120px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 15px;
  color: #fff;
  cursor: default !important;
}

.red-btn:hover,
.redd_btn:hover {
  background-color: #000000;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 15px;
  color: #fff;
  cursor: default !important;
}

.yell_btn {
  background-color: #FFCD03;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  width: 70px;
  text-transform: uppercase;
  padding: 5px 15px;
  color: #fff;
  cursor: default !important;
}

.yelll_btn {
  background-color: #FFCD03;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  width: 120px;
  text-transform: uppercase;
  padding: 5px 15px;
  color: #fff;
  cursor: default !important;
}

.yellowd_btn {
  background-color: #FFCD03;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 25px;
  color: #fff;
  cursor: default !important;
}

.yellowd_btn:hover {
  background-color: #000000;
}


.yell_btn:hover,
.yelll_btn:hover {
  background-color: #000000;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 15px;
  color: #fff;
  cursor: default !important;
}


.green-btn {
  background-color: #17c93b;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  /* width: 100px; */
  text-transform: uppercase;
  padding: 5px 20px;
  color: #fff;
  cursor: pointer !important;
}

.greenn_btn {
  background-color: #17c93b;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  width: 120px;
  text-transform: uppercase;
  padding: 5px 20px;
  color: #fff;
  cursor: pointer !important;
}


.partial_btn {
  background-color: #E15098;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 20px;
  color: #fff;
  cursor: pointer !important;
}

.receive_btn {
  background-color: #FC59AA;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 20px;
  color: #fff;
  cursor: pointer !important;
}

.green-btn:hover,
.greenn_btn:hover {
  background-color: #000000;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 20px;
  color: #fff;
  cursor: pointer !important;
}

.blue-btn {
  background-color: #18d6dc;
  border: none;
  border-radius: 30px;
  width: 100px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 32px;
  color: #fff;
  cursor: pointer !important;
}

.blue-btn:hover {
  background-color: #000000;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 32px;
  color: #fff;
  cursor: pointer !important;
}

.lt_sky_blue_btn {
  background-color: #24BC84;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  /* width: 100px; */
  text-transform: uppercase;
  padding: 5px 15px;
  color: #fff;
  cursor: pointer !important;
}


.sky_blue_btn {
  background-color: #2BD998;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  width: 100px;
  text-transform: uppercase;
  padding: 5px 15px;
  color: #fff;
  cursor: pointer !important;
}

.sky_bluee_btn {
  background-color: #2BD998;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 43px;
  color: #fff;
  cursor: pointer !important;
}

.sky_blue_btn:hover,
.lt_sky_blue_btn:hover {
  background-color: #000000;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 15px;
  color: #fff;
  cursor: pointer !important;
}

.royal-btn {
  background-color: #177de2;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  width: 100px;
  text-transform: uppercase;
  padding: 5px 15px;
  color: #fff;
  cursor: pointer !important;
}

.royal-btn:hover {
  background-color: #000000;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 15px;
  color: #fff;
  cursor: pointer !important;
}

.lt_pink_btn {
  background-color: #E15098;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  width: 100px;
  text-transform: uppercase;
  padding: 5px 15px;
  color: #fff;
  cursor: pointer !important;
}

.pink-btn {
  background-color: #bb4af2;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  width: 100px;
  text-transform: uppercase;
  padding: 5px 15px;
  color: #fff;
  cursor: pointer !important;
}

.violet_btn {
  background-color: #B01A40;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  width: 100px;
  text-transform: uppercase;
  padding: 5px 15px;
  color: #fff;
  text-align: center;
  cursor: pointer !important;
}

.violet_btn:hover {
  background-color: #000000;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 15px;
  color: #fff;
  cursor: pointer !important;
}


.pink-btn:hover,
.lt_pink_btn:hover {
  background-color: #000000;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 15px;
  color: #fff;
  cursor: pointer !important;
}

.yellow-btn {
  background-color: #3663b6;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  width: 100px;
  text-transform: uppercase;
  padding: 5px 27px;
  color: #fff;
  cursor: pointer !important;
}

.yellow-btn:hover {
  background-color: #000000;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 27px;
  color: #fff;
  cursor: pointer !important;
}

.dark-btn {
  background-color: #ec2828;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  width: 100px;
  text-transform: uppercase;
  padding: 5px 15px;
  color: #fff;
  cursor: default !important;
}

.dark-btn:hover {
  background-color: #000000;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 15px;
  color: #fff;
  cursor: default !important;
}

.generate_btn {
  background-color: #17c93b;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  width: 100px;
  text-transform: uppercase;
  padding: 5px 20px;
  color: #fff;
  cursor: pointer;
}

.generate_btn:hover {
  background-color: #000000;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 20px;
  color: #fff;
  cursor: pointer;
}

.cancelled-btn {
  background-color: #ec2828;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  width: 100px;
  text-transform: uppercase;
  padding: 5px 15px;
  color: #fff;
  cursor: pointer;
}

.cancelled-btn:hover {
  background-color: #000000;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 15px;
  color: #fff;
  cursor: pointer;
}

.draft-btn {
  background-color: #18d6dc;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 28px;
  color: #fff;
  cursor: pointer !important;
}

.draft-btn:hover {
  background-color: #000000;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 28px;
  color: #fff;
}

.boq-generate-btn {
  background-color: #c53b5b;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 20px;
  color: #fff;
  cursor: pointer !important;
}

.boq-generate-btn:hover {
  background-color: #000000;
  border: none;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
  padding: 5px 20px;
  color: #fff;
}