/* .profile input[type="file"] {
  display: block!important;
} */

.main-border {
  background-color: red !important;
}

.rotate-img img {
  width: 17px;
  transform: rotate(180deg);
  margin-right: 10px;
  filter: invert(100%) sepia(0%) saturate(4307%) hue-rotate(109deg) brightness(107%) contrast(101%);
}

.rotate-img {
  cursor: pointer;
  outline: none;
  border: none;
  background-color: #2fa5cd;
  font-size: 12px;
  margin-left: 22px;
  border-radius: 15px;
  color: #fff;
  box-shadow: 0 2px #5a5a5a;
  padding: 7px 17px;
}

.bulk-pop-up-table {
  margin: 20px;
}

.bulk-pop-up-table tbody tr {
  border-bottom: 1px solid rgb(201, 201, 201);
}

.upload-area-main {
  padding: 20px;
  text-align: center;
}

.upload-area {
  border-radius: 3px;
  border: 3px dashed grey;
  cursor: pointer;
  height: 200px;
  width: 95%;
  margin: 10px;
}

.upload-area-main button {
  background-color: #ff7f03;
  color: #fff;
  padding: 10px 50px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
}

.upload-area-main button:active {
  background-color: #bc5c00;
  transform: translateY(2px);
}

.upload-area-main img {
  width: 100px;
  margin-top: 20px;
}

.upload-area h1 {
  margin-top: 80px;
  font-size: 18px;
}

.upload-area h2 {
  font-size: 18px;
}

.bulk-pop-up-table {
  height: 500px;
  overflow: scroll;
}

.shade {
  background-color: rgb(255, 255, 232);
}

.down-bulk {
  cursor: pointer;
}

.down-bulk a {
  color: #000;
}

.down-bulk img {
  width: 18px;
  position: relative;
  right: 5px;
}

.pop-item {
  max-width: 60px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.save-btn-pop {
  text-align: center;
}

.txt-right {
  text-align: right;
}

.txt-center {
  text-align: center;
}

.pop-label-main label,
.date-sign {
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
  margin-right: 10px;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}

.form-oa-main {
  width: 100%;
}

.form-oa {
  max-width: 45vw !important;
  margin: 40px auto;
}

.form-oa label {
  font-size: 14px;
  font-weight: 500;
}

.form-oa-main .form-oa input {
  color: #000 !important;
}

.select-input {
  margin-top: 20px;
  margin-left: 20px;
  padding: 5px 10px;
  box-shadow: 0px 1px 1px #0000001a;
  border: none;
}

.infinite-table {
  margin-top: 20px;
}

.infinite-table table tbody tr td {
  padding: 19px 5px 10px 5px;
  color: #aaaaaa;
  font-weight: 400;
  white-space: nowrap;
}

.infinite-table table tbody tr td.cell-txt-center {
  padding-left: 50px;
  text-align: center;
}

.infinite-table table tbody tr td.ms-cell-txt-center {
  padding-left: 35px;
}

.infinite-table table tbody tr td:hover {
  color: #ff7f03;
  transition-duration: 0.3s;
}

.no-data-hg {
  display: flex;
  min-height: 300px !important;
  justify-content: center;
  align-content: center;
  margin-top: 200px;
}

.infinite-table .inward_new_size {
  max-width: 13rem;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.search-imgs {
  position: relative;
  left: 25px;
  width: 14px;
  z-index: 99;
}

.new-filter-set button {
  position: relative !important;
  left: 20px !important;
}

.new-filter-set-report button {
  position: relative !important;
  left: 15px !important;
  bottom: 3px !important;
}

#scrollableDiv {
  overflow: auto;
  height: 550px !important;
}

.infinite-scroll-component {
  overflow: inherit !important;
}

.td-size td {
  max-width: 150px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-head tbody tr td {
  vertical-align: baseline !important;
}

.border-tag h1 {
  font-size: 16px;
  margin-top: 10px;
  border: 1px solid #a09e9e;
  text-align: center;
  padding: 5px;
  cursor: pointer;
}

.under-line {
  text-decoration: underline;
}

.tool-space {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tool-spaces {
  min-width: 150px !important;
  max-width: 150px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tool-space span p {
  background-color: transparent !important;
  color: #aaaaaa !important;
}

.tab-new {
  padding: 30px 20px;
  height: 550px;
  overflow: scroll;
}

.pdf-img {
  cursor: pointer;
}

.download_btnn img {
  width: 17px;
  margin-right: 10px;
  filter: invert(100%) sepia(0%) saturate(4307%) hue-rotate(109deg) brightness(107%) contrast(101%);
}

.download_btnn:active {
  background-color: red;
}

.popover {
  z-index: 1060 !important;
}

.download_btnn {
  cursor: pointer;
  outline: none;
  border: none;
  background-color: #871596;
  font-size: 12px;
  border-radius: 15px;
  color: #fff;
  box-shadow: 0 2px #5a5a5a;
  padding: 5px 17px;
}

.page-head-split div .tab-down {
  width: 50% !important;
  white-space: nowrap;
}

.ant-upload-list-text-container {
  display: none !important;

}

/* .ant-upload {
  padding-top: 50px !important;
  padding-bottom: 60px !important;
} */

.remove_img {
  width: 30px;
  z-index: 99;
  position: absolute;
  right: 5px;
  top: 15px;
  cursor: pointer;
}